import { fileTypeToImage } from "../util/Utilities";
import { useEffect, useState } from "react";
import blank from "../assets/images/file_types/blank.png";
import TrashedFileActionsComponent from "./TrashedFileActionsComponent";


const TrashedFileGrid = ({ file, onDelete, onUpdate, onRestore }) => {

    const [currentFile, setCurrentFile] = useState(null);

    const [imageToDisplay, setImageToDisplay] = useState(blank);

    useEffect(() => {
        if (currentFile) {
            fileTypeToImage(currentFile?.extension, currentFile?.id).then((response) => {
                setImageToDisplay(response);
            });
        }
    }, [currentFile])

    useEffect(() => {
        if (file) setCurrentFile(file);
    }, [file])

    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
            <div className="card p-3">
                <div className="d-flex justify-content-between mb-2">
                    <p style={{ fontSize: 16, cursor: 'pointer' }} className="mb-0 text-truncate custom-link-primary" title={currentFile?.name}>
                        {currentFile?.name}
                    </p>
                    <TrashedFileActionsComponent
                        onRestore={onRestore}
                        file={currentFile}
                        onDelete={onDelete}
                    />
                </div>
                <img className="file-grid-image" src={imageToDisplay} alt={currentFile?.name} style={{ width: '100%', cursor: 'pointer' }} />
            </div>
        </div>
    )
}
export default TrashedFileGrid;