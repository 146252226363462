import Dashboard from "../pages/private/Dashboard";
import SystemInfo from "./SystemInfo";
import { FaInbox, FaChartBar, FaListUl } from "react-icons/fa";

import { TiDocument } from "react-icons/ti";
import { MdOutlineDocumentScanner } from "react-icons/md";
import ModuleIndexPage from "../components/ModuleIndexPage";
import DocumentTypeColumns from "../components/CustomTable/Columns/DocumentTypeColumns";
import DocumentTypeFiltersComponent from "../components/FiltersComponents/DocumentTypeFiltersComponent";
import DocumentTypesActionsComponent from "../components/DocumentTypesActionsComponent";
import CreateDocumentType from "../pages/private/document-types/CreateDocumentType";
import UpdateDocumentType from "../pages/private/document-types/UpdateDocumentType";
import Reports from "../pages/private/Reports";
import FilesPreview from "../pages/private/files/FilesPreview";
import ActivityLogColumns from "../components/CustomTable/Columns/ActivityLogColumns";
import ActivityLogFiltersComponent from "../components/FiltersComponents/ActivityLogFiltersComponent";
import DeletedDocuments from "../pages/private/DeleteDocuments";




const createLink = (
    title,
    hidden,
    Component,
    Icon,
    path,
    permissions,
    Columns,
    FiltersComponent,
    endPoint,
    GlobalActionsComponent,
    ModuleActionsComponent,
    children
) => ({ title, hidden, Component, Icon, path, permissions, Columns, FiltersComponent, endPoint, GlobalActionsComponent, ModuleActionsComponent, children });

const { systemCode } = SystemInfo;

export const mainPermissions = {
    documentTypes: [`${systemCode}-can-view-document-types`, `${systemCode}-can-create-document-types`, `${systemCode}-can-update-document-types`, `${systemCode}-can-delete-document-types`],
    activityLogs: [`${systemCode}-can-view-activity-logs`],
    reports: [`${systemCode}-can-view-reports`]
}

const MenuLinks = [
    createLink('Documentos', null, Dashboard, TiDocument, '/dashboard', null, null, null, null, null, null, null),
    createLink('Tipos de documentos', null, ModuleIndexPage, MdOutlineDocumentScanner, '/tipos-de-documentos', mainPermissions?.documentTypes?.[0], DocumentTypeColumns, DocumentTypeFiltersComponent, 'document-types', null, DocumentTypesActionsComponent, null),
    createLink('Registro de actividades', null, ModuleIndexPage, FaListUl, '/registro-de-actividades', mainPermissions?.activityLogs?.[0], ActivityLogColumns, ActivityLogFiltersComponent, 'activity-logs', null, null, null),
    createLink('Crear tipos de documentos', true, CreateDocumentType, null, '/tipos-de-documentos/crear', mainPermissions?.documentTypes?.[1], null, null, 'document-types', null, null, null),
    createLink('Editar tipos de documentos', true, UpdateDocumentType, null, '/tipos-de-documentos/:id', mainPermissions?.documentTypes?.[0], null, null, 'document-types', null, null, null),
    createLink('Previsualizar Documento', true, FilesPreview, null, '/documentos/:id/preview', null, null, null, 'files', null, null, null),
    createLink('Reportes', null, Reports, FaChartBar, '/reportes', mainPermissions?.reports?.[0], null, null, 'reports', null, null, null),
    createLink('Baúl de archivos', null, DeletedDocuments, FaInbox, '/papelera', null, null, null, null, null, null, null),
];

export default MenuLinks;