import { useState } from "react"
import { Dropdown } from "react-bootstrap";
import { FaEllipsisV, FaEye } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";
import DeleteFileModal from "./DeleteFileModal";
import DetailFileModal from "./DetailFileModal";
import RestoreFileModal from "./RestoreFileModal";


const TrashedFileActionsComponent = ({ file, onSave, onDelete, onRestore }) => {

    const [show, setShow] = useState('');

    const handleSave = (newFile) => {
        onSave?.(newFile);
        setShow('');
    }

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle variant="null" className="p-0 text-primary" id="dropdown-basic">
                    <FaEllipsisV />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShow('restore')}>
                        <HiOutlineRefresh />
                        Restaurar
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShow('detail')}>
                        <FaEye />
                        Ver detalles
                    </Dropdown.Item>
                    <Dropdown.Item className="text-danger" onClick={() => setShow('delete')}>
                        <MdDeleteForever />
                        Eliminar
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <RestoreFileModal
                file={file}
                onRestore={onRestore}
                show={show === 'restore'}
                setShow={setShow}
            />
            <DeleteFileModal
                file={file}
                onDelete={onDelete}
                show={show === 'delete'}
                setShow={setShow}
            />
            <DetailFileModal
                file={file}
                hiddenUpdate
                show={show === 'detail'}
                onUpdate={onSave}
                setShow={setShow}
            />
        </div>
    )
}

export default TrashedFileActionsComponent