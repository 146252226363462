import FileList from "./FileList";

const FileListComponent = ({ files, onDelete, onUpdate, onRestore, FileCustomComponent }) => {
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            Nombre
                        </th>
                        <th>
                            Código
                        </th>
                        <th>
                            Estatus
                        </th>
                        <th>
                            Fecha de creación
                        </th>
                        <th>
                            Propietario
                        </th>
                        <th>
                            Fecha de expiración
                        </th>
                        <th className="text-end">
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        files?.map((file, i) => {
                            return (
                                FileCustomComponent ?
                                    <FileCustomComponent
                                        onRestore={onRestore}
                                        onUpdate={onUpdate}
                                        onDelete={onDelete}
                                        file={file}
                                        key={`documentos-lista-${file?.id}-${Date.now()}`}
                                    />
                                    :
                                    <FileList
                                        onUpdate={onUpdate}
                                        onDelete={onDelete}
                                        file={file}
                                        key={`custom-documentos-lista-${file?.id}-${Date.now()}`}
                                    />
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default FileListComponent;