import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Breadcrumb, Button, ButtonGroup, Spinner } from "react-bootstrap";
import { FaList, } from "react-icons/fa";
import { MdGridView } from "react-icons/md";
import FileListComponent from "../../components/FileListComponent";
import FileGridComponent from "../../components/FileGridComponent";
import { useFileFilters } from "../../context/FileFiltersContext";
import TrashedFileList from "../../components/TrashedFileList";
import TrashedFileGrid from "../../components/TrashedFileGrid";




const DeletedDocuments = () => {

    const { filters, setFilters } = useFileFilters();

    const [{ data, loading }, getFiles] = useAxios({ url: `/trashed`, params: filters });

    const [mode, setMode] = useState('list');

    return (
        <div>
            <div className="row">
                <div className="col-md-8">
                    <ButtonGroup aria-label="Basic example">
                        <Button onClick={() => setMode('list')} variant={mode === 'list' ? 'primary' : "outline-secondary"}>
                            <FaList />
                        </Button>
                        <Button onClick={() => setMode('grid')} variant={mode === 'grid' ? 'primary' : "outline-secondary"}>
                            <MdGridView />
                        </Button>
                    </ButtonGroup>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar..."
                            value={filters?.search}
                            onChange={(e) => setFilters(oldFilters => ({ ...oldFilters, search: e.target.value }))}
                        />
                    </div>
                </div>
            </div>
            <br />
            {
                loading &&
                <div className="text-center mt-auto">
                    <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                </div>
            }

            {
                data?.data?.length === 0 && !loading ?
                    <h6 className="text-center">
                        La papelera esta vacia.
                    </h6>
                    :
                    null
            }

            {mode === 'list' && data?.data?.length > 0 ? <FileListComponent FileCustomComponent={TrashedFileList} onRestore={() => getFiles({ params: filters })} onUpdate={() => getFiles({ params: filters })} onDelete={() => getFiles({ params: filters })} files={data?.data} /> : null}
            {mode === 'grid' && data?.data?.length > 0 ? <FileGridComponent FileCustomComponent={TrashedFileGrid} onRestore={() => getFiles({ params: filters })} onUpdate={() => getFiles({ params: filters })} onDelete={() => getFiles({ params: filters })} files={data?.data} /> : null}
        </div >
    )
}

export default DeletedDocuments;