import { Modal, Spinner } from "react-bootstrap";
import useAxios from "../hooks/useAxios";

const RestoreFileModal = ({ show, file, onRestore, setShow }) => {

    const [{ loading }, restoreFile] = useAxios({ url: `/files/${file?.id}/restore`, method: 'POST' }, { manual: true, useCache: false });

    const handleRestore = (e) => {
        e.preventDefault?.();

        restoreFile?.().then(res => {
            onRestore?.();
            setShow(false);
        });
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Restaurar Archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    ¿Estas seguro que quieres restaurar el archivo?
                    <br />
                    Al restaurarlo si hay un archivo con la misma ruta, será reemplazado.
                </p>
                <p className="text-primary">
                    <b>
                        {file?.name}
                    </b>
                </p>
                <div className="text-end">
                    <button type="button" className="btn text-dark me-1 fw-bold" onClick={() => setShow(false)}>
                        Cancelar
                    </button>
                    <button disabled={loading} className="btn text-primary me-1 fw-bold" onClick={handleRestore}>
                        {
                            loading ?
                                <Spinner animation="grow" />
                                :
                                'Si, quiero restaurarlo'
                        }
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RestoreFileModal;