import { useEffect, useState } from "react";
import { Button, Dropdown, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap"
import { FaLink } from "react-icons/fa";
import useAxios from "../hooks/useAxios";


const CreateFileLinkComponent = ({ parentId, onCreate }) => {

    const [show, setShow] = useState(false);

    const [data, setData] = useState({
        file_id: '',
        parent_id: ''
    })

    const [{ data: storeData, loading: storeLoading }, storeFileLink] = useAxios({ url: `/files/link`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        setData((oldData) => {
            return {
                ...oldData,
                parent_id: parentId
            }
        });
    }, [parentId]);

    const handleChange = (e) => {
        setData(old => {
            return {
                ...old,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleCreate = (e) => {
        e.preventDefault?.();
        storeFileLink({ data }).then((response) => {
            setShow(false);
            onCreate?.();
        })
    }

    return (
        <>
            <Dropdown.Item onClick={() => setShow(true)}>
                <FaLink />
                Acceso directo
            </Dropdown.Item>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Acceso directo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleCreate}>
                        <FloatingLabel
                            controlId="folder_name"
                            label="Ingrese el código del documento"
                            className="mb-3"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Ingrese el código del documento"
                                name="file_id"
                                value={data?.file_id}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="text-end">
                            <button type="button" className="btn text-danger me-1 fw-bold" onClick={() => setShow(false)}>
                                Cancelar
                            </button>
                            <button disabled={storeLoading} className="btn text-primary me-1 fw-bold">
                                {
                                    storeLoading ?
                                        <Spinner animation="grow" />
                                        :
                                        'Crear'
                                }
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateFileLinkComponent;