import { fileTypeToImage } from "../util/Utilities";
import { useEffect, useState } from "react";
import { useFileFilters } from "../context/FileFiltersContext";
import FileActionsComponent from "./FileActionsComponent";
import { useNavigate } from "react-router-dom";
import blank from "../assets/images/file_types/blank.png";


const FileGrid = ({ file, onDelete, onUpdate }) => {

    const navigate = useNavigate();

    const [currentFile, setCurrentFile] = useState(null);

    const [imageToDisplay, setImageToDisplay] = useState(blank);

    useEffect(() => {
        if (currentFile) {
            fileTypeToImage(currentFile?.extension, currentFile?.id).then((response) => {
                setImageToDisplay(response);
            });
        }
    }, [currentFile])

    const { setFilters } = useFileFilters();

    useEffect(() => {
        if (file) setCurrentFile(file);
    }, [file])

    const handleFile = () => {
        if (currentFile?.extension === 'dir') {
            setFilters(oldFilters => ({ ...oldFilters, parent_id: currentFile?.id }));
            return;
        }

        navigate(`/documentos/${file?.id}/preview`);
    }

    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
            <div className="card p-3">
                <div className="d-flex justify-content-between mb-2">
                    <p style={{ fontSize: 16, cursor: 'pointer' }} className="mb-0 text-truncate custom-link-primary" onClick={handleFile} title={currentFile?.name}>
                        {currentFile?.name}
                    </p>
                    <FileActionsComponent
                        file={currentFile}
                        onSave={(newFile) => {
                            setCurrentFile(newFile);
                            onUpdate?.();
                        }}
                        onDelete={onDelete}
                    />
                </div>
                <img
                    className="file-grid-image"
                    src={imageToDisplay}
                    alt={currentFile?.name}
                    style={{ width: '100%', cursor: 'pointer', objectFit: 'cover' }}
                    onClick={handleFile}
                />
            </div>
        </div>
    )
}
export default FileGrid;