import { fileTypeToImage } from "../util/Utilities";
import { useFileFilters } from "../context/FileFiltersContext";
import { useEffect, useState } from "react";
import profile from "../assets/images/profile.png";
import FileActionsComponent from "./FileActionsComponent";
import { MdModeEdit } from "react-icons/md";
import EditExpiredAtComponent from "./EditExpiredAtComponent";
import { useNavigate } from "react-router-dom";
import blank from "../assets/images/file_types/blank.png";

const FileList = ({ file, onDelete, onUpdate }) => {

    const navigate = useNavigate();

    const [currentFile, setCurrentFile] = useState(null);

    const { setFilters } = useFileFilters();

    const [editExpiredAt, setEditExpiredAt] = useState(false);

    const [imageToDisplay, setImageToDisplay] = useState(blank);

    useEffect(() => {
        if (file) setCurrentFile(file);
    }, [file])

    useEffect(() => {
        if (currentFile) {
            fileTypeToImage(currentFile?.extension, currentFile?.id).then((response) => {
                setImageToDisplay(response);
            });
        }
    }, [currentFile])

    const handleFile = () => {
        if (currentFile?.extension === 'dir') {
            setFilters(oldFilters => ({ ...oldFilters, parent_id: currentFile?.id }));
            return;
        }

        navigate(`/documentos/${file?.id}/preview`);
    }

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center custom-link-primary" style={{ cursor: 'pointer' }} onClick={handleFile}>
                    <img src={imageToDisplay} alt={currentFile?.name} style={{ height: 40, width: 40 }} />
                    <p className="m-0 ms-2" style={{ fontSize: 14 }}>
                        {currentFile?.name}
                    </p>
                </div>
            </td>
            <td>
                {currentFile?.prefix || '--'}
            </td>
            <td style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {currentFile?.status === 'vigente' && <p className="m-0 text-success">{currentFile?.status || '--'}</p>}
                {currentFile?.status === 'por vencer' && <p className="m-0 text-warning">{currentFile?.status || '--'}</p>}
                {currentFile?.status === 'vencido' && <p className="m-0 text-danger">{currentFile?.status || '--'}</p>}
            </td>
            <td>
                {currentFile?.created_at}
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <img src={currentFile?.user?.imagePath || profile} alt={currentFile?.user?.name} style={{ width: 40, height: 40, borderRadius: '100%', objectFit: 'cover' }} />
                    <p className="m-0 ms-2">
                        {currentFile?.user?.name}
                    </p>
                </div>
            </td>
            <td>
                {
                    editExpiredAt ?
                        <>
                            <EditExpiredAtComponent
                                file={currentFile}
                                onUpdate={onUpdate}
                                setEditExpiredAt={setEditExpiredAt}
                            />
                        </>
                        :
                        <>
                            {currentFile?.expire_at || '--'}
                            {
                                currentFile?.currentUserCanUpdate &&
                                <button className="btn btn-xs p-2" style={{ fontSize: 16 }} onClick={() => setEditExpiredAt(true)}>
                                    <MdModeEdit />
                                </button>
                            }
                        </>
                }
            </td>
            <td className="text-end">
                <FileActionsComponent
                    file={currentFile}
                    onSave={(newFile) => {
                        setCurrentFile(newFile);
                        onUpdate?.();
                    }}
                    onDelete={onDelete}
                />
            </td>
        </tr>
    )
}
export default FileList;