import { fileTypeToImage } from "../util/Utilities";
import { useEffect, useState } from "react";
import profile from "../assets/images/profile.png";

import blank from "../assets/images/file_types/blank.png";
import TrashedFileActionsComponent from "./TrashedFileActionsComponent";

const TrashedFileList = ({ file, onDelete, onUpdate, onRestore }) => {

    const [currentFile, setCurrentFile] = useState(null);

    const [imageToDisplay, setImageToDisplay] = useState(blank);

    useEffect(() => {
        if (file) setCurrentFile(file);
    }, [file])

    useEffect(() => {
        if (currentFile) {
            fileTypeToImage(currentFile?.extension, currentFile?.id).then((response) => {
                setImageToDisplay(response);
            });
        }
    }, [currentFile])

    const handleFile = () => {

    }

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center custom-link-primary" style={{ cursor: 'pointer' }} onClick={handleFile}>
                    <img src={imageToDisplay} alt={currentFile?.name} style={{ height: 40, width: 40 }} />
                    <p className="m-0 ms-2" style={{ fontSize: 14 }}>
                        {currentFile?.name}
                    </p>
                </div>
            </td>
            <td>
                {currentFile?.prefix || '--'}
            </td>
            <td style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {currentFile?.status === 'vigente' && <p className="m-0 text-success">{currentFile?.status || '--'}</p>}
                {currentFile?.status === 'por vencer' && <p className="m-0 text-warning">{currentFile?.status || '--'}</p>}
                {currentFile?.status === 'vencido' && <p className="m-0 text-danger">{currentFile?.status || '--'}</p>}
            </td>
            <td>
                {currentFile?.created_at}
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <img src={currentFile?.user?.imagePath || profile} alt={currentFile?.user?.name} style={{ width: 40, height: 40, borderRadius: '100%' }} />
                    <p className="m-0 ms-2">
                        {currentFile?.user?.name}
                    </p>
                </div>
            </td>
            <td>
                {currentFile?.expire_at || '--'}
            </td>
            <td className="text-end">
                <TrashedFileActionsComponent
                    onRestore={onRestore}
                    file={currentFile}
                    onDelete={onDelete}
                />
            </td>
        </tr>
    )
}
export default TrashedFileList;