import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { file_deleted, file_updated } from "../../util/NotificationsTypes";
import { es } from "date-fns/locale";
import { Link } from "react-router-dom";
import { useFileFilters } from "../../context/FileFiltersContext";

const NotificationRow = ({ notification, onReadNotification }) => {

    const { setFilters } = useFileFilters();

    const [currentNotification, setCurrentNotification] = useState(null);

    const [{ }, setReadNotification] = useAxios({ url: `/cuenta/notificaciones/${currentNotification?.id}/read`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (notification) {
            setCurrentNotification(notification);
        }
    }, [notification])

    const timeDistance = (date, length) => {
        if (date) {
            const dateDistance = formatDistance(new Date(date), new Date(), {
                locale: es
            });

            if (dateDistance?.length > length) {
                return `${dateDistance?.slice(0, length)}...`
            }

            return dateDistance;
        }
    }

    const handleClick = () => {
        if (!currentNotification?.is_read) {
            setReadNotification().then((response) => {
                setCurrentNotification((old) => {
                    return {
                        ...old,
                        ...response?.data
                    }
                });
                onReadNotification?.();
            });
        }



        if (currentNotification?.type === file_updated) setFilters(oldFilters => ({ ...oldFilters, parent_id: currentNotification?.notificationable?.parent_id }));
    }

    return (
        <li onClick={handleClick} title={`${currentNotification?.description}.`} style={{ cursor: 'pointer' }} className="menu-link animate__animated animate__fadeInUp">
            <div className="timeline-panel">
                <div className="media-body">
                    <h6 className="mb-1 text-primary">
                        {currentNotification?.title}: {currentNotification?.notificationable?.name || ''}
                    </h6>
                    <p className="mb-1">
                        {currentNotification?.description?.length > 30 ?
                            `${currentNotification?.description?.slice(0, 30)}...`
                            :
                            currentNotification?.description
                        }
                    </p>
                    <small className="d-block">
                        <b>Hace: {timeDistance(currentNotification?.created_at, 20)}</b>
                    </small>
                </div>
                {
                    !currentNotification?.read_at &&
                    <small className="float-right text-muted pl-2 text-capitalize">
                        <b>Nueva</b>
                    </small>
                }
            </div>
        </li>
    )
}

export default NotificationRow;