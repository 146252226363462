import { useState } from "react";
import { useTheme } from "../context/ThemeContext";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";

const ActivityLogActionsDropdown = ({ value }) => {

    const { darkMode } = useTheme();

    const [show, setShow] = useState(false);

    return (
        <>
            <Dropdown className="dropdown ms-auto text-right">
                <Dropdown.Toggle
                    variant=""
                    className="btn-link i-false"
                    data-toggle="dropdown"
                >
                    <BsThreeDots style={{ color: darkMode ? 'white' : '', fontSize: 20 }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                    <Dropdown.Item onClick={() => setShow(true)}>
                        Ver cambios
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Cambios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="text-center">
                                Antes
                            </h4>
                            {
                                value?.properties?.old ?
                                    <table className="table">
                                        <tbody>
                                            {
                                                Object.keys(value?.properties?.old).map((key, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-start text-capitalize">
                                                                <b>
                                                                    {key}
                                                                </b>
                                                            </td>
                                                            <td className="text-end">
                                                                {value?.properties?.old[key] || 'Sin datos.'}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    null
                            }
                        </div>
                        <div className="col-md-6">
                            <h4 className="text-center">
                                Depues
                            </h4>
                            {
                                value?.properties?.attributes ?
                                    <table className="table">
                                        <tbody>
                                            {
                                                Object.keys(value?.properties?.attributes).map((key, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-start text-capitalize">
                                                                <b>
                                                                    {key}
                                                                </b>
                                                            </td>
                                                            <td className="text-end">
                                                                {value?.properties?.attributes[key] || 'Sin datos.'}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)} className="w-100">
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ActivityLogActionsDropdown;