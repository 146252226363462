import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Breadcrumb, Button, ButtonGroup, Dropdown, Spinner } from "react-bootstrap";
import clsx from "clsx";
import { FaList, FaPlus } from "react-icons/fa";
import { MdGridView } from "react-icons/md";
import CreateFileDirComponent from "../../components/CreateFileDirComponent";
import FileListComponent from "../../components/FileListComponent";
import FileGridComponent from "../../components/FileGridComponent";
import { useFileFilters } from "../../context/FileFiltersContext";
import CreateFileComponent from "../../components/CreateFileComponent";
import CreateFileLinkComponent from "../../components/CreateFileLinkComponent";



const Dashboard = () => {

    const { filters, setFilters, paths, setPaths, currentFileDir } = useFileFilters();

    const [{ data, loading }, getFiles] = useAxios({ url: `/files`, params: filters });

    const [{ data: storeFileData, loading: storeFileLoading }, storeFile] = useAxios({ url: `/files`, method: 'POST' }, { manual: true, useCache: false });

    const [mode, setMode] = useState('list');

    const handlePath = (path) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                parent_id: path?.id
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-4">
                    <ButtonGroup aria-label="Basic example">
                        <Button onClick={() => setMode('list')} variant={mode === 'list' ? 'primary' : "outline-secondary"}>
                            <FaList />
                        </Button>
                        <Button onClick={() => setMode('grid')} variant={mode === 'grid' ? 'primary' : "outline-secondary"}>
                            <MdGridView />
                        </Button>
                    </ButtonGroup>
                    {
                        currentFileDir?.currentUserCanCreate &&
                        <Dropdown className="d-inline ms-2">
                            <Dropdown.Toggle variant="primary">
                                <FaPlus />
                                Nuevo
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <CreateFileDirComponent onCreate={() => getFiles({ params: filters })} parentId={filters?.parent_id} />
                                <CreateFileComponent onCreate={() => getFiles({ params: filters })} parentId={filters?.parent_id} />
                                <CreateFileLinkComponent onCreate={() => getFiles({ params: filters })} parentId={filters?.parent_id} />
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                <div className="col-md-8">
                    <Breadcrumb>
                        {
                            paths?.map((path, i) => {
                                return (
                                    <Breadcrumb.Item onClick={() => handlePath(path)} active={i + 1 === paths?.length} className={clsx({
                                        'text-primary': i + 1 === paths?.length
                                    })}>
                                        {path?.name}
                                    </Breadcrumb.Item>
                                )
                            })
                        }
                    </Breadcrumb>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar..."
                            value={filters?.search}
                            onChange={(e) => setFilters(oldFilters => ({ ...oldFilters, search: e.target.value }))}
                        />
                    </div>
                </div>
            </div>
            <br />
            {
                loading &&
                <div className="text-center mt-auto">
                    <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                </div>
            }

            {
                data?.data?.length === 0 && !loading ?
                    <h6 className="text-center">
                        Esta carpeta esta vacia
                    </h6>
                    :
                    null
            }

            {mode === 'list' && data?.data?.length > 0 ? <FileListComponent onUpdate={() => getFiles({ params: filters })} onDelete={() => getFiles({ params: filters })} files={data?.data} /> : null}
            {mode === 'grid' && data?.data?.length > 0 ? <FileGridComponent onUpdate={() => getFiles({ params: filters })} onDelete={() => getFiles({ params: filters })} files={data?.data} /> : null}
        </div >
    )
}

export default Dashboard;