import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import useAxios from "../../../hooks/useAxios";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getAuth } from "../../../helpers/auth";
import SystemInfo from "../../../util/SystemInfo";

const FilesPreview = () => {

    const { id } = useParams();

    const authInfo = JSON.parse(`${getAuth()}`);

    const headers = {
        'Authorization': `Bearer ${authInfo?.token}`
    };

    const [{ data, loading }, getDocument] = useAxios({ url: `/files/${id}` }, { useCache: false });



    return (
        <div>
            {
                loading ?
                    <div className="text-center mt-auto">
                        <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                    </div>
                    :
                    data ?
                        <DocViewer
                            language="es"
                            documents={[{ fileName: data?.data?.name, uri: `${SystemInfo?.api}/files/${id}/download` }]}
                            requestHeaders={headers}
                            pluginRenderers={DocViewerRenderers}
                            style={{ minHeight: 600 }}
                        />
                        :
                        <h4 className="text-center">
                            No se pudo obtener el preview.
                        </h4>
            }
        </div>
    )
}

export default FilesPreview;