import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import { dateFine } from "../../../util/Utilities";
import DateFormatter from "../../DateFormatter";
import UserHavePermission from "../../../util/UserHavePermission";
import SystemInfo from "../../../util/SystemInfo";
import ModelToModuleName from "../../ModelToModuleName";
import ActivityLogActionsDropdown from "../../ActivityLogActionsDropdown";
import ModelToRecordName from "../../ModelToRecordName";

const ActivityLogColumns = [
    {
        Label: TableCheck,
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Responsable de la acción',
        Component: ({ value }) => `${value?.causer?.name}`
    },
    {
        Label: () => 'acción',
        Component: ({ value }) => `${value?.description || '--'}`
    },
    {
        Label: () => 'modulo',
        Component: ({ value }) => <ModelToModuleName value={value} />
    },
    {
        Label: () => 'nombre del registro',
        Component: ({ value }) => <ModelToRecordName value={value} />
    },
    {
        Label: () => 'Fecha de acción',
        Component: ({ value }) => `${value?.created_at}`
    },
    {
        Label: () => 'Acciones',
        Component: ActivityLogActionsDropdown
    }
];

export default ActivityLogColumns;