import FileGrid from "./FileGrid";

const FileGridComponent = ({ files, onDelete, onUpdate, FileCustomComponent, onRestore }) => {
    return (
        <div className="row">
            {
                files?.map((file, i) => {
                    return (
                        FileCustomComponent ?
                            <FileCustomComponent
                                onRestore={onRestore}
                                onUpdate={onUpdate}
                                onDelete={onDelete}
                                file={file}
                                key={`filegrid-${file?.id}-${Date.now()}`}
                            />
                            :
                            <>
                                <FileGrid
                                    onDelete={onDelete}
                                    file={file}
                                    key={`filegrid-${file?.id}-${Date.now()}`}
                                    onUpdate={onUpdate}
                                />
                            </>
                    )
                })
            }
        </div>
    )
}

export default FileGridComponent;